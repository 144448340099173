import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export const generateRandomString = (prependValue, appendValue) => {
  let initialValue = (Math.random() * 9999999999).toFixed(0);
  if (prependValue) initialValue = `${prependValue}-${initialValue}`;
  if (appendValue) initialValue = `${initialValue}-${appendValue}`;
  return initialValue;
};

export const fileAttributesFromUri = (uri, prependValue, appendValue) => {
  const file = uri;
  const fileType = file.type;
  const fileExt = fileType.split("/")[1];
  const fileName = `${generateRandomString(
    prependValue,
    appendValue
  )}.${fileExt}`;
  const filePath = `${fileName}`;

  return { file, fileName, fileExt, fileType, filePath };
};

export const timeAgo = (date) => {
  if (!date) return "Unknown";
  const _date = new Date(date);
  return dayjs(_date).fromNow();
};

export const timeNow = () => {
  const currentDate = new Date();
  return currentDate.toISOString();
};

export const getAvailableDays = (currentMonthName, daysList) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthNumber = monthNames.indexOf(currentMonthName);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentDay = today.getDate();

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const numberOfDaysInMonth = getDaysInMonth(monthNumber, currentYear);

  if (today.getMonth() === monthNumber) {
    return daysList.filter(day => day.value >= currentDay && day.value <= numberOfDaysInMonth);
  } else {
    return daysList.filter(day => day.value <= numberOfDaysInMonth);
  }
};

export const getAvailableMonths = (inputMonths) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonthIndex = new Date().getMonth();

  return inputMonths.filter((month) => {
    const monthIndex = monthNames.indexOf(month.label);
    return monthIndex >= currentMonthIndex;
  });
};

export const formatToBlogDate = (date = new Date()) =>
  dayjs(date).format("MMM D, YYYY");

const getExtensionFromUrl = (url) => {
  const urlParts = url.split(".");
  if (urlParts.length > 1) {
    return urlParts[urlParts.length - 1];
  } else {
    return "";
  }
};

export const isPhotoExtension = (url) => {
  const photoExtensions = ["jpg", "jpeg", "png", "gif"];
  const urlExtension = getExtensionFromUrl(url);
  return photoExtensions.includes(urlExtension.toLowerCase());
};

export const isPdfExtension = (url) => {
  const urlExtension = getExtensionFromUrl(url);
  return urlExtension.toLowerCase() === "pdf";
};

export const generateSocialMediaURL = (platform, handle) => {
  if (!handle || !handle.startsWith("@")) {
    throw new Error('Handle should start with "@"');
  }

  const cleanHandle = handle.substring(1); // Remove the '@' from the beginning

  switch (platform.toLowerCase()) {
    case "twitter":
      return `https://x.com/${cleanHandle}`;
    case "instagram":
      return `https://www.instagram.com/${cleanHandle}/`;
    case "tiktok":
      return `https://www.tiktok.com/@${cleanHandle}`;
    case "snapchat":
      return `https://www.snapchat.com/add/${cleanHandle}`;
    default:
      throw new Error("Unsupported platform");
  }
};

export const groupImpressionsByMonth = (impressions) => {
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthCounts = {};

  // Initialize counts for all months to zero
  MONTHS.forEach((month) => {
    monthCounts[month] = 0;
  });

  // Update the counts based on impressions data
  impressions.forEach((impression) => {
    const date = new Date(impression.timestamp);
    const month = MONTHS[date.getUTCMonth()];
    monthCounts[month]++;
  });

  return monthCounts;
};

export const getUniqueImpressionsCount = (impressions) => {
  const uniqueSessions = new Set(
    impressions.map((impression) => impression.session_id)
  );
  return uniqueSessions.size;
};

export const getUniqueLocationsCount = (dataArray) => {
  const uniqueStates = new Set();
  const uniqueCities = new Set();
  const uniqueLocations = new Set();

  for (const item of dataArray) {
    uniqueStates.add(item.state);
    uniqueCities.add(item.city);
    uniqueLocations.add(item.location);
  }

  return {
    states: uniqueStates.size,
    cities: uniqueCities.size,
    locations: uniqueLocations.size,
  };
};

export const stringToArray = (str, separator = ",") => {
  if (!str) return [];
  return str.split(separator);
};

export const arrayToString = (arr, separator = ",") => {
  if (!Array.isArray(arr)) return "";
  return arr.join(separator);
};
